#popup-languages{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top:0px;
    left:0px;
    z-index: 100;
   
    .pop-container{
        width: 50%;

        .pop-header{
            font-size: 24px;
        }

        .pop-body{
            max-height: 500px;
            overflow-y: auto;
        }
    }
}