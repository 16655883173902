.login-container{
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f8f8f8;

    .login-box{
        width: 380px;
        border-radius: 5px;
        background-color: white;

        .textfield{

        }
    }

}