.progress-background{
    background:rgba(255,255,255,0.7);
    width:100%;
    height:100dvh;
    position: absolute;
    left: 0px;
    top:0px;
}

.progress-circle{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .circle-container{
        width: fit-content;
    }

    .circle-text{
        font-size: 24px;
        margin-bottom: 10px;
    }
}