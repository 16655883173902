.pop-map-background{
    position: absolute;
    top: 0px;
    left:0px;
    width: 100%;
    height: 100vh;
    background-color: rgba(255,255,255,0.5);
}

.pop-map{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #bababa;
    padding: 20px 40px;
    border-radius: 5px;

    .pop-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 15px 0px;
        align-items: center;

        .map-name{
            font-size: 24px;
        }
    }
    .pop-body{
        

        .hotspots-body{
            width: 800px;
            position: relative;
            top:0px;
            left: 0px;

            img{
                user-select: none;
                position: absolute;
            }

            .hotspot{
                position: absolute;
                font-size: 36px;
                cursor: pointer;
                transform: translate(-18px, -36px);
                
            }
        }
    }

    .pop-footer{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 15px 0px;
    }
}