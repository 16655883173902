.pop-background{
    position: absolute;
    width: 100%;
    height: 100dvh;
    left: 0px;
    top:0px;
    background-color: rgba(255,255,255,0.5);
}

.pop-hsp-container{
    position: absolute;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 3px 3px 8px #969696;
    padding: 20px;
    

    .pop-header{
        
       padding: 10px 0px;
    }

    .pop-content{
        margin: 10px 0px;
        width: 1200px;
        height: 600px;
    }

    .pop-footer{
        padding: 10px 0px;
    }
}